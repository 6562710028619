'use strict';
var linkCss = document.createElement("link");
linkCss.rel = "stylesheet";
linkCss.href = "https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600,700,800|Open+Sans:300,400,600,700";
var siteHeadElem = document.head || document.getElementsByTagName("head")[0];
siteHeadElem.appendChild(linkCss);
'use strict';

var overlay = {

  init: function(){
		this.buildOverlay();
  },
  
  buildOverlay: function(){
    setTimeout(function () {
      $('.overlay > svg').fadeIn('slow');
    }, 100);
    setTimeout( function() {
      $('.overlay > svg').fadeOut('slow');
      $('.overlay__logo').fadeIn('slow');
    }, 1000);
    setTimeout(function () {
      $('.overlay').fadeOut();
    }, 2000);
    $timeout = ($('.overlay').length>0) ? 3000 : 500;
    setTimeout(function () {
      $('body').addClass('show-top-bar');
    }, $timeout);
  }

}
'use strict';

var topBar = {

	init: function(){
    this.hamburgerMenu();
    this.searchOverlay();
    this.getCollapseMenuPosition();
    this.checkOpenCollapse();
	},

	// Stiky menù
  stikyMenu: function(){
    var myMenu = $(".top");
    var scroll = $(window).scrollTop();
    if (scroll >= 110) {
      myMenu.addClass("stiky-navbar");
    } else {
      myMenu.removeClass("stiky-navbar");
      /*if(myMenu.parent().hasClass('t_page_home')){
        $('.searchOverlay').fadeOut("slow");
        $('.top__search').removeClass('is-active');
      }*/
    }
  },

  //Hamburger Menu
  hamburgerMenu: function () {
    $('.c-hamburger').click(function (event) {
      event.stopPropagation();
      $(this).toggleClass('is-active');
      if ($(this).hasClass('is-active')) {
        $('.overlayMenuContainer').fadeIn("slow");
        var menuContainerHeight = $(".overlayMenuContainer__container").height();
        var liCount = $(".overlayMenuContainer__container ul li").length;
        $(".overlayMenuContainer__container ul li").each(function (i, obj) {
          $(obj).css("transition-delay", (100 * i) + "ms");
          $(obj).removeClass("introOut");
          $(obj).addClass("intro");
          var myHeight = $(obj).height();
          myPadding = ((menuContainerHeight - 80 - (myHeight * liCount)) / liCount) / 2;
          $(obj).css('padding-top', myPadding);
          $(obj).css('padding-bottom', myPadding);
        });
      } else {
        $('.overlayMenuContainer').fadeOut("slow");
        $(".overlayMenuContainer__container ul li").each(function (i, obj) {
          $(obj).css("transition-delay", (100 * i) + "ms");
          $(obj).removeClass("intro");
          $(obj).addClass("introOut");
        });
      }
    });
  },

  //Overlay Search
  searchOverlay: function () {
    $('.top__search').click(function (event) {
      event.stopPropagation();
      $(this).toggleClass('is-active');
      if ($(this).hasClass('is-active')) {
        $('.searchOverlay').fadeIn("slow");
      } else {
        $('.searchOverlay').fadeOut("slow");
      }
    });
  },

  getCollapseMenuPosition: function() {
    $('.top__nav li').each(function(){
      var left = $(this).offset().left;
      $(this).children('.top__collapse-menu').css("left", -left);
    });
  },

  checkOpenCollapse: function() {
    $('.top__nav__link').click(function(){
      if ($(this).parent().siblings().children('.top__collapse-menu').hasClass('show')){
        $(this).parent().siblings().children('.top__collapse-menu').removeClass('show');
        $(this).parent().siblings().children('.top__nav__link').addClass('collapsed');
      }
    });
  }

}
'use strict';

var visual = {

	init: function(){
    this.slickSlider();
    $('.slick-dots').addClass('container');
    if($('.visual-catalog__item').length<2){
      $('.slick-dots').hide();
    }

   
    this.playVid();
	},

  playVid: function () {
    var vid = document.getElementById("myvideo");
    var flag = $(".overlay");
    /*if(vid){
      checkFlag();
      function checkFlag() {
        if(flag.is(":visible")) {
          window.setTimeout(checkFlag, 300); 
        } else {
          vid.play();
        }
      }
    } */
  },


  slickSlider:function(){
    $('.visual-catalog--slider').slick({
      dots: true,
      infinite: true,
      speed: 700,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      pauseOnFocus: false,
      pauseOnHover: false
    });
  }
	
}
'use strict';

var download = {
  init: function() {
    $('#downloadTexture').on('click', function(e) {
      e.preventDefault();
      alert('Download texture');
    });

    // Funzione per aggiungere l'event listener al form di ogni modal
    function setupModalFormListener(modalElement, pdfUrl) {
      if (modalElement) {
        const form = modalElement.querySelector('form');
        if (form) {
          form.addEventListener('submit', function(event) {
            const observer = new MutationObserver(function(mutations) {
              mutations.forEach(function(mutation) {
                if (mutation.type === 'childList') {
                  const swalSuccess = document.querySelector('.swal2-success');
                  if (swalSuccess && window.getComputedStyle(swalSuccess).display !== 'none') {
                    window.open(pdfUrl, '_blank');
                    observer.disconnect();
                  }
                }
              });
            });
            observer.observe(document.body, { childList: true, subtree: true });
          });
        }
      }
    }

    // Array di oggetti che contengono l'ID della modal e l'URL del PDF
    const modals = [
      { id: 'exampleModal', pdfUrl: '/files/prodotti/gms_texture_brochure_08_38034.pdf' },
      { id: 'exampleModal2', pdfUrl: '/files/prodotti/catalogo_elementum_20_40499.pdf' },
      { id: 'exampleModal3', pdfUrl: '/files/prodotti/libro_elementum_2-0_92927.pdf' }
    ];

    // Iterazione sull'array di modals per configurare gli event listener
    modals.forEach(function(modal) {
      const modalElement = document.getElementById(modal.id);
      setupModalFormListener(modalElement, modal.pdfUrl);
    });
  }
};


$(document).ready(function() {
  $('[data-toggle="modal"]').click(function() {
    var title = $(this).data('title');
    var downloadUrl = $(this).data('download-url');
    
    $('#universalModalLabel').text(title);
    // Memorizza l'URL del download in un attributo dell'elemento modale
    $('#universalModal').data('download-url', downloadUrl);
  });

  // Funzione per aggiungere l'event listener al form di ogni modal
  function setupModalFormListener(modalElement) {
    if (modalElement) {
      const form = modalElement.querySelector('form');
      if (form) {
        form.addEventListener('submit', function(event) {
          const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
              if (mutation.type === 'childList') {
                const swalSuccess = document.querySelector('.swal2-success');
                if (swalSuccess && window.getComputedStyle(swalSuccess).display !== 'none') {
                  // Recupera l'URL del download memorizzato e apri in una nuova scheda
                  const downloadUrl = $(modalElement).data('download-url');
                  window.open(downloadUrl, '_blank');
                  observer.disconnect();
                }
              }
            });
          });
          observer.observe(document.body, { childList: true, subtree: true });
        });
      }
    }
  }

  // Configura gli event listener per la modale esistente
  const modalElement = document.getElementById('universalModal');
  setupModalFormListener(modalElement);
});
'use strict';

var company = {

  init: function(){
    $('.image-0').addClass('active');
    this.hoverEffects();
    this.slickSlider();
  },

  hoverEffects: function(){
    var item = $('.company__aside a');
    item.hover(function(){
      var className = $(this).attr('class'); // Rinominato da 'class' a 'className'
      var splitClass = className.split('-');
      var num = splitClass[1];
      $('.image-' + num).addClass('active');
      if ($('.image-' + num).siblings().hasClass('active')){
        $('.image-' + num).siblings().removeClass('active');
      }
    }, 
    function(){
      var className = $(this).attr('class'); // Rinominato da 'class' a 'className'
      var splitClass = className.split('-');
      var num = splitClass[1];
      $('.image-' + num).removeClass('active');
    });
  
    $('.company__aside ul').mouseleave(function () {
      $('.image-0').addClass('active');
    });
  },

  slickSlider: function () {
    $('.company__slider').slick({
      dots: false,
      infinite: true,
      arrows: true
    });
  }
};
'use strict';

var product = {

	init: function(){
    this.slickSlider();
	},

  slickSlider: function () {
    $('.product__gallery__content').slick({
      dots: false,
      infinite: true,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 500,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    });
  }
}
var barbaJs = {
	init: function(){
		Barba.Pjax.start();
		Barba.Dispatcher.on('transitionCompleted', function() {
			
			$('html, body').scrollTop(0);
			mm_track_pageview(location.pathname);
			siteGlobal.rebuildAllEvents();

		});

		Barba.Dispatcher.on('newPageReady',function(currentStatus, oldStatus, container){
			var js = container.querySelectorAll("script");
			if(js != null && js.length>0){
				for(i in js){
					if(js[i].innerHTML!=='') eval(js[i].innerHTML);
					if(js[i].src!=='') $.getScript(js[i].src);
				}
			}
		});

		Barba.Dispatcher.on('linkClicked', function() {
		});

		// Barba Google Analytics
		Barba.Dispatcher.on('initStateChange', function () {
			if (typeof ga !== 'function' || Barba.HistoryManager.history.length <= 1) {
				return;
			}

			ga('send', 'pageview', window.location.pathname);
		});

		Barba.Pjax.getTransition = function() {
			return FadeTransition;
		};

		Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
		
		Barba.Pjax.preventCheck = function(evt, element) {
			if (!Barba.Pjax.originalPreventCheck(evt, element)) {
				return false;
				alert('');
			}
			// No need to check for element.href -
			// originalPreventCheck does this for us! (and more!)
			if (/.pdf/.test(element.href.toLowerCase())) {
				return false;
			}
			return true;
		};
	}
}

FadeTransition = Barba.BaseTransition.extend({
	start: function() {
		Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
	},
	fadeOut: function() {
		return $(this.oldContainer).animate({ opacity: 0 }).promise();
	},

	fadeIn: function() {
		var _this = this;
		var $el = $(this.newContainer);

		$(this.oldContainer).hide();

		$el.css({
			visibility : 'visible',
			opacity : 0
		});

		$el.animate({ opacity: 1 }, 400, function() {
			_this.done();
		});
	}
});
'use strict';
var siteGlobal = {

  options : {
    breakpoints: { 480: 'xxs',768: 'xs', 980: 'sm', 1200: 'md', 5000: 'lg' },
  },

  defaultScrollOffset:0,

  initReady: function () {
    this.breakPointUtility();
    this.goToPosOffsetCalc();
    this.linkAll();
    this.linkParts();
    this.goToContact();
    this.goToIframe();
    topBar.init();
    visual.init();
    company.init();
    download.init();
    //product.init();
  },

  initLoad: function () {
    this.goToPosOffsetCalc();
    this.breakPointUtility();
    setTimeout(function(){
      lazyLoad.init();
    },150);
    this.gmapPageInit();
    this.wow = new WOW();
    this.wow.init();
    this.modalVideoInit();
    resArea.init();
    overlaySocialShare.init();
  },

  initFirstLoad: function(){
    barbaJs.init();
    this.overlayAllOff();
    if($(window).width()>768){
      overlay.init();
    }
  },

  rebuildAllEvents: function(){
    this.initReady();
    this.initLoad();
  },

  initResize: function(){
    this.breakPointUtility();
    topBar.stikyMenu();
    topBar.getCollapseMenuPosition();
  },

  initResizeEnd: function(){
    this.goToPosOffsetCalc();
    topBar.getCollapseMenuPosition();
  },

  initScroll: function(){
    topBar.stikyMenu();
    topBar.getCollapseMenuPosition();
  },


  /**
   * OPEN MODAL (Usata di solito per social)
   * @param  {string} url : url pagina da aprire nella nuova finestra
   */
  openModal:function(url) {
    url = decodeURIComponent(((url) + '').replace(/\+/g, '%20'));
    window.open(url, 'socials', 'height=450,width=880,toolbar=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes');
  },


  /**
   * ELEMENTI INTERAMENTE LINKABILI
   */
  linkAll: function(){
    $('.linkFrom').each(function() { 
        var elemToLink = $(this).parents('.linkTo');
        var pageLink = $(this).attr('href');
        var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
        var nobarba = $(this).hasClass('no-barba');
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink, myTarget:linkTarget, nobarba:nobarba},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.myLink); 
            } else {
              location.href = e.data.myLink;
            }
          }
        });
      }
    }); 
  },

  /**
   * ELEMENTI PARZIALMENTE LINKABILI (aree diversificate all'interno dello stesso contenitore)
   */
  linkParts: function(){
    var elems = $('.linkFromPart');
    var elemsLg = elems.length;
    if(elemsLg>0){
      var i=0;
      for (i; i<=elemsLg-1; i++) {
        var el = $(elems[i]);
        var pageLink = el.attr('href');
        if(pageLink !== undefined) {
          var blockWrap = el.parents('.linkWrap');
          var elemsToLink = $('.linkToPart',blockWrap);
          var nobarba = $(this).hasClass('no-barba');
          elemsToLink.addClass('pointer').click({pageLink:pageLink,nobarba:nobarba},function(e){
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.pageLink); 
            } else {
              location.href = e.data.pageLink;
            }
          });
        }
      }
    }
  },

  /**
   * COMPORTAMENTI JS A SECONDA DELLA DIMENSIONE PAGINA
   */
  breakPointUtility: function(){

    siteGlobal.breakPoint = 'xs';

    for (var el in this.options.breakpoints){
      if (this.options.breakpoints.hasOwnProperty(el)) {
        if ( $(window).width() <= (el-1) ){
            siteGlobal.breakPoint = this.options.breakpoints[el];
            break;
        }
      }
    }      
  },

  /**
   * OVERLAY LOADER
   */
  overlayAllOff : function() {
      setTimeout(
          function(){
              $('.overlay-all').remove();;
          },
      150);   
  },

  /**
   * CALCOLA OFFSET PER ANIMAZIONE SCROLL PAGINA
   */
  goToPosOffsetCalc:function(status){
    siteGlobal.defaultScrollOffset = 0;
    
    // Elementi da considerare per l'offset (un selettore per elemento)
    // Es. var offsetElements=['#barTopWrap'];
    var offsetElements=[];
    
    if (offsetElements.length > 0) {
      for (var el in offsetElements){
        var $el = $(offsetElements[el]);
        if($el.length>0){

          // L'elemento viene considerato nel calcolo solo se è in position fixed
          var elPos = $el.css('position');
          if(elPos === 'fixed') siteGlobal.defaultScrollOffset+= $el.outerHeight(true);
        }
      }
    }

    // Gestisco il padding del body rispetto all'offset
    $('body').css({'paddingTop':siteGlobal.defaultScrollOffset});


  }, 

  /**
   * SCROLL PAGINA
   */
  goToPos: function(ref,offset){
    // Offset Default con calcolo barre menu top
    var defaultOffset = siteGlobal.defaultScrollOffset;

    if(typeof offset === 'undefined') offset = defaultOffset;
    $ref = (typeof ref==='string') ? $(ref) : ref ;
    if($(ref).length==0)
      return false;
    var myPos = $ref.offset().top-offset;
    $('html,body').animate({
      scrollTop: myPos}, 
      { duration: 600 }
    );
  },

  gmapPageInit:function(){
    if($('#gmap_jquery').length>0 && typeof mapConfig!=='undefined') {
     mapConfig.params.drag = ($(document).width() > 768) ? true : false;
     setDirection.set(
      'gmap_jquery', 
      mapConfig.config,
      mapConfig.params.zoom,
      mapConfig.params.type,
      mapConfig.params.showCursor,
      mapConfig.params.drag,
      mapConfig.params.scroll
     );
    }
  },

  goToContact: function () {
    $('.goToContact').click(function () {
      $("html, body").animate({ scrollTop: $('.form-contact').offset().top }, 1000);
      if($(this).hasClass('product__link--datasheet')){
        var product_name = $("input[name='SERVICE_TITLE']").val();
        $("input[name='SERVICE_TITLE']").attr('value', trad['request technical data sheet'] + ' - ' + product_name);
      } else if($(this).hasClass('product__link--info')){
        var product_name = $("input[name='SERVICE_TITLE']").val();
        $("input[name='SERVICE_TITLE']").attr('value', trad['info request'] + ' - ' + product_name);
      }
    });
  },

  goToIframe: function(){
    $('.goToIframe').click(function () {
      $("html, body").animate({ scrollTop: $('.saviolab__iframe').offset().top }, 1000);
    });
  },

  modalVideoInit: function () {
    var time = ($(window).width() > 767) ? 2500 : 1000;
    /*var iframe_url = $('#modal-video iframe').attr('src');
    var video_url = $('#modal-video video source').attr('src');
    $('#modal-video iframe').attr('src', '');
    $('#modal-video video source').attr('src', '');*/
    if ($('#modal-video').length > 0) {
      setTimeout(function () {
        $('#modal-video').modal('show');
        //$('#modal-video iframe').attr('src', iframe_url);
        //$('#modal-video video source').attr('src', video_url);
        var url_video = $('#video-embed').data('vid');
        $('#video-embed').append('<video width="100%" class="vplayer" controls autoplay><source src="'+url_video+'">Your browser does not support the video tag.</video>');
        $('#modal-video .close').on('click', function(){
          //$('#modal-video iframe').attr('src', '');
          $('#modal-video video').remove();
        });
      }, time);
    }
  }
  

};





/**
 * INIZIALIZZAZIONE SITO
 */
(function () {

  siteGlobal.initReady();

  $( window ).on("load",function() {
      siteGlobal.initLoad();
      siteGlobal.initFirstLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });
  

  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }

  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

}());